@import "../../node_modules/bootstrap-4-grid/css/grid.min.css";

@import "../../node_modules/swiper/swiper-bundle.min.css";

@import "../../node_modules/swiper/swiper-bundle.min.css";

.dimbox-container {
  position: fixed;
  inset: 0 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 56px;
  opacity: 0;
  transition: opacity 0.5s;
  z-index: 99999;
}

.dimbox-container.dimbox-light {
  color: #000;
}

.dimbox-container.show {
  opacity: 1;
}

.dimbox-container.close-on-overlay-click,
.dimbox-container.close-on-overlay-click .dimbox-figure,
.dimbox-container.close-on-overlay-click .dimbox-video-container,
.dimbox-container.close-on-overlay-click .dimbox-iframe-container,
.dimbox-container.close-on-overlay-click .dimbox-ajax-container,
.dimbox-container.close-on-overlay-click:not(.dimbox-loaded) .dimbox-image {
  cursor: pointer;
}

.dimbox-container * {
  box-sizing: border-box;
}

.dimbox-container::before {
  content: "";
  display: block;
  position: absolute;
  inset: 0 0 0 0;
  background-color: #000;
  opacity: 0.75;
}

.dimbox-container.dimbox-light::before {
  background-color: #fff;
}

.dimbox-loader {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 32px;
  height: 32px;
  margin-top: -16px;
  margin-left: -16px;
  border: 4px solid #fff;
  border-right-color: rgba(0, 0, 0, 0);
  border-radius: 50%;
  opacity: 0.5;
  animation: spin 1s linear infinite;
}

.dimbox-container.dimbox-light .dimbox-loader {
  border-color: #000;
  border-right-color: rgba(0, 0, 0, 0);
}

.dimbox-container.dimbox-loaded .dimbox-loader {
  display: none;
}

[class^=dimbox-btn] {
  position: absolute;
  width: 32px;
  height: 32px;
  display: inline-block;
  background-color: rgba(0, 0, 0, 0);
  color: #fff;
  padding: 0;
  border: none;
  opacity: 0.5;
  cursor: pointer;
}

.dimbox-container.dimbox-light [class^=dimbox-btn] {
  color: #000;
}

.dimbox-btn-close {
  top: 16px;
  right: 16px;
}

.dimbox-btn-download {
  top: 16px;
  right: 64px;
}

[class^=dimbox-btn]:hover,
[class^=dimbox-btn]:active,
[class^=dimbox-btn]:focus {
  color: #fff;
  opacity: 1;
}

.dimbox-container.dimbox-light [class^=dimbox-btn]:hover,
.dimbox-container.dimbox-light [class^=dimbox-btn]:active,
.dimbox-container.dimbox-light [class^=dimbox-btn]:focus {
  color: #000;
}

.dimbox-btn-prev,
.dimbox-btn-next {
  top: 50%;
}

.dimbox-btn-prev {
  left: 8px;
  transform: rotate(180deg) translateY(50%);
}

.dimbox-btn-next {
  right: 8px;
  transform: translateY(-50%);
}

[class^=dimbox-btn] svg {
  width: 32px;
  height: 32px;
}

.dimbox-sequence {
  position: absolute;
  top: 16px;
  left: 16px;
}

.dimbox-content {
  position: relative;
  max-height: 100%;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.5s;
  cursor: default;
}

.dimbox-container.show .dimbox-content {
  opacity: 1;
}

.dimbox-container.dimbox-gallery .dimbox-content.dimbox-transition-left {
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.5s, transform 0.5s;
}

.dimbox-container.dimbox-gallery .dimbox-content.dimbox-transition-right {
  transform: translateX(50%);
  opacity: 0;
  transition: opacity 0.5s, transform 0.5s;
}

.dimbox-figure,
.dimbox-video-container,
.dimbox-iframe-container {
  margin: 0;
  opacity: 0;
  transition: opacity 0.5s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: calc(100vh - 88px);
  transition: width 0.5s, height 0.5s;
}

.dimbox-iframe-container {
  width: calc(100vw - 112px);
  height: calc(100vh - 88px);
}

.dimbox-ajax-container {
  display: flex;
  flex-direction: column;
}

.dimbox-container.dimbox-loaded .dimbox-figure,
.dimbox-container.dimbox-loaded .dimbox-video-container,
.dimbox-container.dimbox-loaded .dimbox-iframe-container {
  opacity: 1;
}

.dimbox-ajax-content,
.dimbox-inline-content {
  background-color: #fff;
  color: #000;
  max-width: 768px;
  max-height: calc(100vh - 88px);
  padding: 16px;
  overflow: auto;
}

.dimbox-image,
.dimbox-video,
.dimbox-iframe {
  display: block;
  min-width: 0;
  min-height: 0;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: 100%;
  cursor: default;
  transition: width 0.5s, height 0.5s;
}

.dimbox-image,
.dimbox-video {
  object-fit: contain;
}

.dimbox-caption {
  color: #fff;
  font-size: 0.85rem;
  text-align: center;
  margin: 8px auto 0 auto;
  cursor: default;
}

.dimbox-container.dimbox-light .dimbox-caption {
  color: #000;
}

.dimbox-iframe {
  border: none;
}

.dimbox-iframe.ratio-16x9 {
  aspect-ratio: 16/9;
}

.dimbox-iframe.ratio-4x3 {
  aspect-ratio: 4/3;
}

.dimbox-iframe.ratio-1x1 {
  aspect-ratio: 1/1;
}

.dimbox-iframe.ratio-9x16 {
  aspect-ratio: 9/16;
}

.dimbox-noscroll {
  overflow: hidden !important;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSans-Regular.woff2") format("woff2"), url("../fonts/OpenSans-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "OpenSans_Condensed";
  src: url("../fonts/OpenSans_Condensed-Bold.woff2") format("woff2"), url("../fonts/OpenSans_Condensed-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "OpenSans_Condensed";
  src: url("../fonts/OpenSans_Condensed-Light.woff2") format("woff2"), url("../fonts/OpenSans_Condensed-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: block;
}

html {
  font-size: 100%;
  scroll-behavior: smooth;
  height: 100%;
  -webkit-text-size-adjust: none;
  -webkit-appearance: none;
  line-height: 1.333em;
  -webkit-text-size-adjust: 100%;
}

body {
  height: 100%;
  width: 100%;
  min-width: 320px;
  margin: 0;
  font-size: 18px;
  line-height: 1.333em;
  font-family: "Open Sans", sans-serif;
  color: #5F5F5F;
  background-color: #ffffff;
  -webkit-font-smoothing: antialiased;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  backface-visibility: hidden;
  text-rendering: geometricPrecision;
}

h1,
.title-1,
h2,
.title-2,
h3,
.title-3,
h4,
.title-4,
h5,
.title-5,
h6,
.title-6 {
  margin: 0;
  font-weight: bold;
  font-family: "OpenSans_Condensed", sans-serif;
  text-transform: uppercase;
}

h1,
.title-1 {
  font-size: 60px;
  line-height: 1.167em;
  margin-bottom: 16px;
}

h2,
.title-2 {
  font-size: 45px;
  line-height: 1.08em;
}

h3,
.title-3 {
  font-size: 25px;
  line-height: 1.4em;
}

h4,
.title-4 {
  font-size: 22px;
  line-height: 1.4em;
}

h5,
.title-5 {
  font-size: 20px;
  line-height: 1.4em;
}

h6,
.title-6 {
  font-size: 18px;
  line-height: 1.4em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  display: inline-block;
  text-decoration: none;
  color: inherit;
  font-size: inherit;
  line-height: 1.6em;
  margin: 0;
  background-color: transparent;
}

a:visited {
  text-decoration: none;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: bold;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img,
picture,
source {
  max-width: 100%;
  width: 100%;
  display: block;
  border-style: none;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

input {
  line-height: 1em;
}

button,
input {
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */

button,
select {
  text-transform: none;
}

select::-ms-expand {
  display: none;
}

select {
  -webkit-appearance: none;
  appearance: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: hidden;
  resize: none;
}

textarea:focus {
  outline: none;
}

label {
  cursor: pointer;
}

[type=checkbox],
[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

p,
li {
  margin: 0;
  color: inherit;
}

ul,
ol {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

section.section {
  position: relative;
  overflow: hidden;
}

tr {
  line-height: 1.5em;
}

figure {
  margin: 0;
}

blockquote {
  margin: 0;
}

button {
  background-color: transparent;
  border: none;
}

button:hover {
  cursor: pointer;
}

button:focus {
  outline: none;
}

input[type=date] {
  display: block;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
}

input[type=date]::-webkit-inner-spin-button {
  display: none;
}

input[type=date]::-webkit-calendar-picker-indicator {
  opacity: 0;
  position: relative;
  right: -10px;
}

input[type=date]::-webkit-inner-spin-button,
input[type=date]::-webkit-calendar-picker-indicator {
  opacity: 0;
  -webkit-appearance: none;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

textarea::-webkit-input-placeholder,
input::-webkit-input-placeholder {
  font-size: 18px;
  color: #ffffff;
}

textarea::-moz-placeholder,
input::-moz-placeholder {
  font-size: 18px;
  color: #ffffff;
}

textarea:-ms-input-placeholder,
input:-ms-input-placeholder {
  font-size: 18px;
  color: #ffffff;
}

textarea::placeholder,
input::placeholder {
  font-size: 18px;
  color: #ffffff;
}

input {
  outline: none;
}

.wrapper {
  position: relative;
  overflow: hidden;
}

.container {
  max-width: 1375px;
}

.container_lg {
  max-width: 1670px;
  margin: 0 auto;
  padding: 0 13px;
  max-width: 87%;
  width: 100%;
}

.main,
main {
  display: block;
  position: relative;
}

.locked {
  overflow: hidden;
}

.icon-social {
  fill: #002841;
  height: 25px;
  width: 27px;
  transition: fill 0.3s ease;
}

.btn {
  text-align: center;
  color: #009EE3;
  border: 1px solid #009EE3;
  background-color: transparent;
  text-transform: uppercase;
  font-size: 20px;
  line-height: 37px;
  height: 40px;
  padding: 0 69px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.btn:hover {
  background-color: #009EE3;
  color: #ffffff;
}

.link {
  font-size: 17px;
  color: #00609B;
  line-height: 1.588em;
  position: relative;
  padding-right: 20px;
  text-transform: uppercase;
}

.link .icon-arrow {
  position: absolute;
  right: 4px;
  top: 5px;
  transform: translateX(0);
  transition: transform 0.3s ease;
}

.link_sm {
  text-transform: lowercase;
}

.link:hover .icon-arrow {
  transform: translateX(3px);
}

.section__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 46px;
  align-items: flex-end;
}

.section__header .link {
  margin-bottom: -10px;
}

.section__title {
  position: relative;
  color: #009EE3;
  text-transform: uppercase;
  padding-left: 84px;
}

.section__title::before {
  content: "////";
  position: absolute;
  left: 0;
  font-size: inherit;
  font-family: inherit;
}

.icon-arrow {
  fill: #00609B;
  height: 16px;
  width: 12px;
  transition: fill 0.3s ease;
}

.date {
  display: inline-block;
  font-size: 17px;
  color: #00609B;
  line-height: 1.588em;
  text-transform: uppercase;
  margin-bottom: 9px;
}

.icon-play {
  width: 38px;
  height: 38px;
  fill: #ffffff;
  margin-left: 5px;
  transform: scale(1);
  transition: transform 0.3s ease;
}

.inner-page .header {
  background-color: #00609B;
  color: #ffffff;
  position: relative;
}

.inner-page .header .icon-social {
  fill: #ffffff;
}

.inner-page .header .burger {
  border-color: #ffffff;
}

.inner-page .header .burger_item {
  background-color: #ffffff;
}

.inner-page .header .nav-list__link::before {
  border-color: #ffffff;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.pagination {
  color: #00609B;
}

.pagination li span.active {
  font-family: "OpenSans_Condensed";
  font-weight: 700;
}

.header {
  padding: 61px 0 52px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  font-size: 20px;
  text-transform: uppercase;
  z-index: 20;
  color: #002841;
}

.header-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header__nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 65%;
}

.logo {
  display: inline-block;
  max-width: 343px;
  flex: 0 0 343px;
  position: relative;
  z-index: 1;
}

.nav {
  margin-right: 2vw;
  width: 100%;
}

.nav-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.nav-list__item:nth-child(-n+2),
.nav-list li:nth-child(-n+2) {
  color: #ffffff;
}

.nav-list__item:nth-child(-n+2) .nav-list__link::before,
.nav-list li:nth-child(-n+2) .nav-list__link::before {
  border-color: #ffffff;
}

.nav-list__link,
.nav-list a {
  padding: 25px 12px;
  position: relative;
  transition: color 0.3s ease;
}

.nav-list__link::before,
.nav-list a::before {
  content: "";
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 2px solid #002841;
  background-color: transparent;
  transition: opacity 0.3s ease;
}

.nav-list__link:hover::before,
.nav-list a:hover::before {
  opacity: 1;
}

.burger {
  position: relative;
  width: 38px;
  height: 38px;
  z-index: 101;
  display: none;
  padding: 8px;
  border: 1px solid #00609B;
}

.burger_item {
  position: absolute;
  width: 22px;
  height: 4px;
  background-color: #00609B;
  left: 8px;
  transition: 0.4s ease-out;
  border-radius: 4px;
}

.burger_item:first-child {
  top: 8px;
}

.burger_item:nth-child(2) {
  top: 50%;
  margin-top: -2px;
}

.burger_item:last-child {
  bottom: 8px;
}

.burger.active {
  border-color: transparent;
}

.burger.active .burger_item {
  background-color: #ffffff;
}

.burger.active .burger_item:first-child {
  transform: rotate(45deg);
  top: 16px;
}

.burger.active .burger_item:nth-child(2) {
  width: 0;
}

.burger.active .burger_item:last-child {
  transform: rotate(-45deg);
  bottom: 16px;
}

.social-list {
  display: flex;
  align-items: center;
}

.social-list__item:not(:last-child) {
  margin-right: 12px;
}

.social-list__link {
  display: flex;
  align-items: center;
  justify-content: center;
}

.social-list__link:hover .icon-social {
  fill: #009EE3;
}

.footer {
  background-color: #002841;
  color: #ffffff;
  padding: 58px 0 53px;
  position: relative;
}

.footer::before {
  content: "";
  width: 14%;
  height: 100%;
  background-image: linear-gradient(to bottom, #ffffff 1px, transparent 1px);
  background-repeat: repeat;
  background-size: 100% 2px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  opacity: 0.1;
}

.footer__logo {
  max-width: 226px;
  margin-bottom: 5px;
}

.footer__logo img {
  height: 34px;
}

.footer__box p {
  font-size: 14px;
  padding-left: 67px;
}

.footer__social-list .icon-social {
  fill: #ffffff;
}

.footer__title {
  font-size: 20px;
  padding-left: 37px;
  margin-bottom: 16px;
}

.footer__row {
  display: flex;
}

.footer-nav {
  font-size: 16px;
  text-transform: uppercase;
  margin-right: 60px;
}

.footer-nav__item {
  white-space: nowrap;
}

.footer-nav__item:not(:last-child) {
  margin-bottom: 5px;
}

.footer-nav__link {
  transition: color 0.3s ease;
}

.footer-nav__link:hover {
  color: #009EE3;
}

.footer__social {
  padding-left: 34px;
}

.hero {
  min-height: 800px;
  color: #ffffff;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 200px 0 69px;
}

.hero::before,
.hero:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
}

.hero::before {
  left: 0;
  background-color: #00609B;
  mix-blend-mode: multiply;
  z-index: 1;
  width: 50vw;
}

.hero::after {
  width: 100%;
  background-image: linear-gradient(to bottom, #ffffff 1px, transparent 1px);
  background-repeat: repeat;
  background-size: 100% 2px;
}

.hero__video {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.hero__video video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero__content {
  position: relative;
  height: 100%;
  z-index: 11;
}

.hero__title {
  max-width: 530px;
}

.hero__title p {
  max-width: 400px;
  margin-bottom: 49px;
}

.hero__title p:not(:first-of-type) {
  display: none;
}

.hero__description {
  color: #002841;
  width: 360px;
  position: absolute;
  bottom: -2px;
  right: -14%;
  font-family: "OpenSans_Condensed";
  font-weight: 300;
  text-transform: uppercase;
  font-size: 24px;
}

.hero__description p:first-child {
  font-weight: 700;
  font-size: 30px;
  margin-bottom: 9px;
}

.hero__img {
  position: absolute;
  bottom: 0;
  left: 54%;
  transform: translate(-50%, 0);
  max-width: 814px;
  width: 42.4%;
  z-index: 10;
}

.partners {
  padding: 73px;
}

.partners-slider {
  margin: 0 65px;
}

.partners-slider .swiper-wrapper {
  display: flex;
  align-items: center;
}

.partners-slider__item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.partners-slider__item img {
  max-height: 70px;
  object-fit: contain;
  aspect-ratio: 16/9;
}

.partners-slider__btns .swiper-button-prev:after,
.partners-slider__btns .swiper-button-next:after {
  content: "";
}

.slider-btn {
  width: 14px;
  height: 37px;
  stroke: #002841;
  fill: none;
}

.news {
  padding: 43px 0 128px 0;
}

.news-card {
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  height: 100%;
  display: flex;
  flex-direction: column;
}

.news-card:hover .link_sm {
  opacity: 1;
}

.news-card__image {
  height: 304px;
}

.news-card__image img {
  object-fit: cover;
  height: 100%;
}

.news-card__content {
  padding: 24px 16px 48px 16px;
  display: flex;
  flex-direction: column;
}

.news-card__content h3,
.news-card__content .title-3 {
  margin-bottom: 24px;
}

.news-card__content p {
  line-height: 1.667em;
  margin-bottom: 23px;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.news-card__content p:not(:first-of-type) {
  display: none;
}

.news-card .link_sm {
  margin-right: 0;
  margin-left: auto;
  margin-bottom: 0;
  margin-top: auto;
  color: #00609B;
  position: absolute;
  bottom: 28px;
  right: 24px;
}

.news-card .link_sm .icon-arrow {
  fill: #00609B;
}

.news__row {
  row-gap: 80px;
}

.events {
  color: #ffffff;
  background-color: #00609B;
  position: relative;
  overflow: hidden;
  margin-bottom: 40px;
}

.events::before {
  content: "";
  width: 136px;
  height: 100%;
  background-image: linear-gradient(to bottom, #ffffff 1px, transparent 1px);
  background-repeat: repeat;
  background-size: 100% 2px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  opacity: 0.2;
}

.events__title {
  color: #ffffff;
  margin-bottom: 47px;
}

.events__content {
  padding: 75px 0 65px;
}

.events__img {
  position: absolute;
  height: 100%;
  right: 0;
  top: 0;
  left: 50%;
  bottom: 0;
}

.events__img img {
  object-fit: cover;
  height: 100%;
}

.events-table {
  text-transform: uppercase;
  font-size: 24px;
}

.events-table table tr td:first-child {
  font-family: "OpenSans_Condensed";
  text-align: right;
  font-weight: 700;
  padding: 0 35px 0 5px;
}

.videos {
  padding: 103px 0;
}

.videos__header {
  margin-bottom: 61px;
}

.videos__item {
  height: 242px;
  position: relative;
  width: 100%;
}

.videos__item video,
.videos__item iframe {
  width: 100%;
  height: 100%;
  border: none;
  object-fit: cover;
  z-index: -1;
}

.play {
  width: 80px;
  height: 80px;
  background-color: #009EE3;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

.play:hover {
  background-color: #002841;
}

.video {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  background-color: #000000;
}

.video__link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video__media {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.video__button {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  display: none;
  padding: 0;
  width: 68px;
  height: 48px;
  border: none;
  background-color: transparent;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.video__button-shape {
  fill: #212121;
  fill-opacity: 0.8;
}

.video__button-icon {
  fill: #ffffff;
}

.video__button:focus {
  outline: none;
}

.video:hover .video__button-shape,
.video__button:focus .video__button-shape {
  fill: #ff0000;
  fill-opacity: 1;
}

/* Enabled */

.video--enabled {
  cursor: pointer;
}

.video--enabled .video__button {
  display: block;
}

a[href$=".mp4"],
a[href^="https://www.youtube.com/embed"] {
  position: relative;
  width: 100%;
}

a[href$=".mp4"]::before,
a[href^="https://www.youtube.com/embed"]::before {
  content: "";
  background-image: url(../images/play.svg);
  background-repeat: no-repeat;
  background-position: 60% center;
  width: 80px;
  height: 80px;
  background-color: #009EE3;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

a[href$=".mp4"]:hover::before,
a[href^="https://www.youtube.com/embed"]:hover::before {
  background-color: #002841;
}

.media {
  padding: 40px 0 127px;
}

.media__header {
  margin-bottom: 62px;
}

.media__row {
  margin: 0 -8px;
}

.media__col {
  width: 25%;
  padding: 0 8px;
  margin-bottom: 16px;
}

.media__item {
  position: relative;
  display: block;
}

.media__item {
  overflow: hidden;
  position: relative;
  display: block;
}

.media__item video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.media__item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mfp-counter {
  display: none;
}

.dimbox-btn-download {
  display: none;
}

.article {
  max-width: 80%;
  margin: 0 auto;
}

.article_inner h2,
.article_inner .title-2 {
  margin: 12px 0 36px;
}

.article_inner img {
  margin: 35px 0 30px 0;
}

.article_inner p {
  margin-bottom: 31px;
  line-height: 1.667em;
}

.article_inner a {
  color: #00609B;
}

.article h2,
.article .title-2 {
  margin: 12px 0 36px;
  font-size: 55px;
  color: #002841;
  line-height: 1.327em;
}

.article img {
  margin: 35px 0 30px 0;
}

.article p {
  margin-bottom: 31px;
  line-height: 1.667em;
}

.article a {
  color: #00609B;
}

.article-page__header {
  margin-bottom: 110px;
}

.awards {
  padding: 120px 0 78px;
  position: relative;
}

.awards__content {
  position: relative;
  z-index: 1;
}

.awards__header {
  display: flex;
  align-items: flex-start;
  margin-bottom: 49px;
  max-width: 520px;
}

.awards__header img {
  max-width: 65px;
  margin-right: 22px;
}

.awards__header p {
  text-transform: uppercase;
  font-size: 28px;
  line-height: 1.357em;
  color: #002841;
}

.awards-list {
  padding-left: 28px;
  margin: 20px 0;
}

.awards-list li {
  position: relative;
  padding-left: 32px;
}

.awards-list li:not(:last-child) {
  margin-bottom: 16px;
}

.awards-list li::before {
  content: "";
  width: 11px;
  height: 11px;
  background-color: #5F5F5F;
  position: absolute;
  left: 0;
  top: 6px;
}

.awards__img {
  position: absolute;
  bottom: 0;
  right: -98px;
  max-width: 56vw;
}

.about {
  overflow: hidden;
}

.about__row {
  min-height: 578px;
  position: relative;
  display: flex;
  align-items: stretch;
}

.about__row .container_lg {
  min-height: 100%;
}

.about__row:nth-child(odd) .about__content {
  color: #ffffff;
  margin-right: 0;
  margin-left: auto;
  padding: 85px 0 0 88px;
  background-color: #00609B;
}

.about__row:nth-child(odd) .about__content::before {
  background-color: #00609B;
}

.about__row:nth-child(odd) .about__img {
  left: 0;
  right: auto;
}

.about__content {
  position: relative;
  flex: 0 0 50%;
  max-width: 50%;
  height: 100%;
  padding: 85px 88px 0 0;
  background-color: #ffffff;
}

.about__content::before {
  content: "";
  width: 50vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.about__content h2,
.about__content .title-2 {
  font-size: 55px;
  margin-bottom: 33px;
}

.about__content p {
  margin-bottom: 43px;
}

.about__img {
  position: absolute;
  top: 0;
  right: 0;
  width: 50vw;
  height: 100%;
}

.about__img img {
  height: 100%;
  object-fit: cover;
}

.news-page {
  padding-top: 140px;
}

.news-page__header {
  margin-bottom: 124px;
}

.partners-hero {
  background-color: #00609B;
  color: #ffffff;
  padding: 125px 0 42px;
  position: relative;
}

.partners-hero__content {
  position: relative;
  z-index: 1;
}

.partners-hero__content h1,
.partners-hero__content .title-1 {
  margin-bottom: 40px;
}

.partners-hero__content p {
  margin-bottom: 40px;
}

.partners-hero__img {
  position: absolute;
  bottom: 0;
  right: 0;
  max-width: 54vw;
}

.partners__row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -12px;
}

.partners__col {
  flex: 0 0 20%;
  padding: 0 12px;
  margin-bottom: 29px;
}

.partners__item {
  height: 180px;
  border-radius: 5px;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 38px 48px;
  position: relative;
  transform: scale(1);
  transition: transform 0.3s ease;
}

.partners__item:hover {
  transform: scale(1.08);
}

.partners__item img {
  max-width: 188px;
}

.instructor-hero {
  background-color: #00609B;
  color: #ffffff;
  padding: 125px 0 93px;
  position: relative;
}

.instructor-hero__content {
  position: relative;
  z-index: 1;
}

.instructor-hero__content h1,
.instructor-hero__content .title-1 {
  margin-bottom: 40px;
}

.instructor-hero__content p {
  margin-bottom: 60px;
}

.instructor-hero__table table tr td:first-child {
  padding-right: 21px;
}

.instructor-hero__img {
  display: none;
}

.gallery__row {
  margin: 0 -8px;
}

.gallery__col {
  width: 25%;
  padding: 0 8px;
  margin-bottom: 16px;
}

.gallery__item {
  height: 100%;
  position: relative;
  display: block;
}

.gallery__item video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  aspect-ratio: 16/9;
}

.gallery__item img {
  height: 100%;
  object-fit: cover;
}

.contact-hero {
  background-color: #00609B;
  color: #ffffff;
  padding: 125px 0 176px;
  position: relative;
}

.contact-hero__title {
  margin-bottom: 30px;
}

.contact-form__box {
  position: relative;
}

.contact-form__box:not(:last-child) {
  margin-bottom: 18px;
  padding-bottom: 20px;
}

.contact-form input[type=submit] {
  margin-top: 31px;
  background-color: #009EE3;
  border: none;
  transition: background-color 0.3s ease;
  width: 100%;
  height: 60px;
  color: #ffffff;
  border-radius: 5px;
}

.contact-form input[type=submit]:hover {
  background-color: #002841;
}

.contact-form input[type=checkbox]:checked ~ .custom-checkbox::before {
  opacity: 1;
}

.contact-form__input {
  width: 100%;
  border: 1px solid #ffffff;
  height: 60px;
  line-height: 60px;
  border-radius: 5px;
  padding: 0 19px;
  background-color: transparent;
  color: #ffffff;
}

.contact-form label {
  position: relative;
  width: 100%;
}

.contact-form__textarea {
  height: 100%;
}

.contact-form__textarea label {
  height: 100%;
  display: inline-block;
}

.contact-form textarea {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.15);
  border: none;
  border-radius: 5px;
  color: #ffffff;
  padding: 17px 19px;
}

.contact-form__row {
  margin-top: 45px;
}

.contact-form__row label {
  display: flex;
  align-items: flex-start;
  font-size: 12px;
  line-height: 20px;
}

.custom-checkbox {
  width: 16px;
  height: 16px;
  margin-right: 12px;
  background-color: #ffffff;
  position: relative;
  flex: 0 0 16px;
  margin-top: 2px;
}

.custom-checkbox::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url(../images/check.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.contact-images {
  position: relative;
  height: 466px;
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;
}

.contact-images img {
  width: 50%;
  height: 100%;
  object-fit: cover;
}

form#contact-form {
  margin: 0 -15px;
}

form#contact-form .form-field {
  flex: 0 0 50%;
  padding: 0 15px;
}

form#contact-form .form-input-wrapper,
form#contact-form .form-textarea-wrapper {
  position: relative;
  margin-bottom: 18px;
  padding-bottom: 20px;
}

form#contact-form .form-input-wrapper textarea,
form#contact-form .form-textarea-wrapper textarea {
  height: 250px;
}

form#contact-form .form-input-wrapper input,
form#contact-form .form-textarea-wrapper input {
  width: 100%;
  border: 1px solid #fff;
  height: 60px;
  line-height: 60px;
  border-radius: 5px;
  padding: 0 19px;
  background-color: transparent;
  color: #fff;
}

form#contact-form .form-data[data-grav-field=checkbox] .form-input-wrapper {
  display: flex;
  align-items: flex-start;
  font-size: 12px;
  line-height: 20px;
}

form#contact-form .form-data[data-grav-field=checkbox] .form-input-wrapper input {
  width: 16px;
  height: 16px;
  margin-right: 12px;
  background-color: #fff;
  position: relative;
  flex: 0 0 16px;
  margin-top: 2px;
}

form#contact-form .buttons {
  max-width: 50%;
  flex: 0 0 50%;
  padding: 0 15px;
  margin: 0 auto;
}

form#contact-form button[type=submit] {
  background-color: #009ee3;
  border: none;
  -webkit-transition: background-color 0.3s ease;
  -o-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
  width: 100%;
  height: 60px;
  color: #fff;
  border-radius: 5px;
}

@media only screen and (max-width: 1800px) {
  h1,
  .title-1 {
    font-size: 50px;
  }

  .container {
    max-width: 1220px;
  }

  .container_lg {
    max-width: 1490px;
  }

  .header {
    padding: 40px 0;
  }

  .logo {
    max-width: 310px;
    flex: 0 0 310px;
  }

  .hero {
    min-height: 710px;
    padding: 200px 0 60px;
  }

  .article h2,
  .article .title-2 {
    font-size: 50px;
  }

  .article-page__header {
    margin-bottom: 80px;
  }

  .awards {
    padding: 80px 0 50px;
  }

  .about__content h2,
  .about__content .title-2 {
    font-size: 50px;
  }

  .news-page {
    padding-top: 100px;
  }

  .news-page__header {
    margin-bottom: 80px;
  }

  .instructor-hero {
    padding: 100px 0 60px;
  }
}

@media only screen and (max-width: 1440px) {
  body {
    font-size: 17px;
  }

  h1,
  .title-1 {
    font-size: 44px;
  }

  h2,
  .title-2 {
    font-size: 42px;
  }

  .container_lg {
    padding: 0 50px;
    max-width: 100%;
  }

  .header {
    padding: 25px 0;
    font-size: 18px;
  }

  .header__nav {
    width: 64%;
  }

  .logo {
    max-width: 240px;
    flex: 0 0 240px;
  }

  .nav-list__link,
  .nav-list a {
    padding: 20px 10px;
  }

  .nav-list__link,
  .nav-list a {
    padding: 5px;
  }

  .footer::before {
    width: 10%;
  }

  .hero {
    min-height: 650px;
  }

  .hero__description {
    font-size: 20px;
  }

  .hero__description p:first-child {
    font-size: 26px;
    margin-bottom: 0;
  }

  .partners {
    padding: 50px 0;
  }

  .partners-slider {
    margin: 0 40px;
  }

  .events::before {
    width: 5%;
  }

  .events__title {
    margin-bottom: 35px;
  }

  .events-table {
    font-size: 20px;
  }

  .article h2,
  .article .title-2 {
    font-size: 46px;
  }

  .article-page__header {
    margin-bottom: 60px;
  }

  .awards {
    padding: 60px 0 50px 0;
  }

  .awards__header {
    margin-bottom: 30px;
  }

  .awards__header p {
    font-size: 26px;
  }

  .awards-list li:not(:last-child) {
    margin-bottom: 10px;
  }

  .about__row {
    min-height: 440px;
  }

  .about__row:nth-child(odd) .about__content {
    padding: 60px 0 0 60px;
  }

  .about__row:nth-child(odd) .about__content {
    padding: 40px 0 0 40px;
  }

  .about__content {
    padding: 60px 60px 0 0;
  }

  .about__content h2,
  .about__content .title-2 {
    font-size: 46px;
    margin-bottom: 30px;
  }

  .about__content p {
    margin-bottom: 30px;
  }

  .instructor-hero__content h1,
  .instructor-hero__content .title-1 {
    margin-bottom: 30px;
  }

  .instructor-hero__content p {
    margin-bottom: 40px;
  }

  .contact-hero {
    padding: 125px 0 100px 0;
  }

  .contact-images {
    height: auto;
  }
}

@media only screen and (max-width: 1280px) {
  .container {
    max-width: 100%;
  }

  .container_lg {
    padding: 0 13px;
  }

  .header__nav {
    width: 63%;
  }

  .hero {
    min-height: 600px;
  }

  .hero__description {
    right: 0;
  }

  .events::before {
    width: 2%;
  }

  .article h2,
  .article .title-2 {
    font-size: 40px;
  }

  .article-page__header {
    margin-bottom: 40px;
  }

  .awards .section__header {
    margin-bottom: 30px;
  }

  .awards__header p {
    font-size: 24px;
  }

  .awards__img {
    right: -248px;
    max-width: 64vw;
  }

  .about__content h2,
  .about__content .title-2 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 1199px) {
  .header__nav {
    width: 62%;
  }

  .events::before {
    display: none;
  }
}

@media only screen and (max-width: 1124px) {
  .awards__img {
    right: -160px;
    max-width: 68vw;
  }
}

@media only screen and (max-width: 1100px) {
  .header {
    color: #ffffff;
    font-size: 22px;
  }

  .header__nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #00609B;
    pointer-events: none;
    opacity: 0;
    padding-left: 23px;
    flex-direction: column;
    align-items: flex-start;
    transform: translateX(-20px);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  }

  .header__nav.active {
    opacity: 1;
    pointer-events: initial;
    transform: translateX(0);
  }

  .header__nav .nav-list {
    flex-direction: column;
    align-items: flex-start;
    padding: 90px 0 0 0;
    justify-content: flex-start;
  }

  .header__social .icon-social {
    fill: #ffffff;
  }

  .nav {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .nav-list__item:not(:last-child),
  .nav-list li:not(:last-child) {
    margin-right: 0;
    margin-bottom: 5px;
  }

  .nav-list__link,
  .nav-list a {
    padding: 5px 5px 5px 0;
  }

  .burger {
    display: inline-block;
  }
}

@media only screen and (max-width: 1024px) {
  .icon-social {
    width: 20px;
    height: 20px;
  }

  .nav {
    margin-right: 10px;
  }

  .article {
    max-width: 100%;
  }

  .awards__img {
    right: -250px;
    max-width: 80vw;
  }

  .partners__col {
    flex: 0 0 25%;
  }

  .partners__item img {
    height: 74px;
    object-fit: contain;
  }

  .partners__item {
    height: 130px;
  }
}

@media only screen and (max-width: 991px) {
  h1,
  .title-1 {
    font-size: 40px;
  }

  h2,
  .title-2 {
    font-size: 38px;
  }

  .container {
    padding: 0 23px;
  }

  .section__header {
    margin-bottom: 35px;
  }

  .section__header .link {
    margin-bottom: 0;
  }

  .footer__social {
    padding-left: 0;
  }

  .hero {
    min-height: auto;
    padding: 160px 0 60px;
  }

  .hero__description {
    top: 0;
    bottom: auto;
    font-size: 18px;
  }

  .hero__description p:first-child {
    font-size: 22px;
  }

  .news {
    padding: 40px 0 80px;
  }

  .news .col-md-6:last-child {
    display: none;
  }

  .news__row {
    row-gap: 60px;
  }

  .events-table {
    font-size: 18px;
  }

  .events-table table tr td:first-child {
    padding: 0 10px 0 0px;
  }

  .videos {
    padding: 40px 0 8px;
  }

  .videos__header {
    margin-bottom: 40px;
  }

  .videos__item {
    margin-bottom: 37px;
  }

  .media {
    padding: 30px 0 80px 0;
  }

  .media__header {
    margin-bottom: 40px;
  }

  .article h2,
  .article .title-2 {
    font-size: 35px;
  }

  .awards-list li:not(:last-child) {
    margin-bottom: 23px;
  }

  .awards__img {
    display: none;
  }

  .about__content {
    padding: 40px 40px 0 0;
  }

  .about__content h2,
  .about__content .title-2 {
    font-size: 35px;
  }

  .news-page {
    padding-top: 60px;
  }

  .news-page__header {
    margin-bottom: 40px;
  }

  .partners-hero {
    padding: 100px 0 0 0;
  }

  .partners-hero__img {
    position: static;
    max-width: 100%;
  }

  .partners__col {
    flex: 0 0 33.333%;
  }

  .instructor-hero {
    padding: 100px 0 0 0;
  }

  .instructor-hero__content {
    margin-bottom: 40px;
  }

  .instructor-hero__img {
    position: absolute;
    bottom: 0;
    right: 0;
    display: block;
    margin: 0 auto;
    max-width: 40%;
  }
}

@media only screen and (max-width: 860px) {
  .instructor-hero__img {
    max-width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  h2,
  .title-2 {
    font-size: 35px;
  }

  .section__header {
    margin-bottom: 25px;
  }

  .burger {
    border-color: #ffffff;
  }

  .burger_item {
    background-color: #ffffff;
  }

  .footer__box {
    margin-bottom: 50px;
  }

  .footer__row {
    flex-wrap: wrap;
  }

  .footer-nav {
    margin-right: 0;
    margin-bottom: 50px;
  }

  .footer-nav:first-child,
  .footer-nav:nth-child(2) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .hero {
    padding: 135px 0 0 0;
  }

  .hero::before {
    width: 100%;
  }

  .hero__video {
    display: none;
  }

  .hero__title {
    max-width: 100%;
  }

  .hero__title p {
    max-width: 100%;
    margin-bottom: 32px;
  }

  .hero__description {
    display: none;
  }

  .hero__img {
    position: static;
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
    transform: translate(0, 0);
  }

  .news {
    padding: 40px 0 60px 0;
  }

  .news-card__content {
    padding: 22px 13px;
  }

  .events__content {
    padding: 40px 0;
  }

  .events__img {
    position: static;
    margin: 0 -10px;
  }

  .videos__header {
    margin-bottom: 32px;
  }

  .media__header {
    margin-bottom: 32px;
  }

  .awards-list {
    padding-left: 0;
  }

  .about__row {
    min-height: auto;
    display: block;
  }

  .about__row:nth-child(odd) .about__content {
    padding: 40px 0 10px;
  }

  .about__content {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 40px 0 10px;
  }

  .about__content::before {
    width: 100vw;
    left: -13px;
  }

  .about__img {
    position: static;
    width: calc(100% + 26px);
    margin: 0 -13px;
  }

  .partners-hero {
    padding: 50px 0 0 0;
  }

  .partners__col {
    flex: 0 0 50%;
  }

  .partners__item {
    padding: 20px 25px;
  }

  .instructor-hero {
    padding: 50px 0 0 0;
  }

  .instructor-hero__img {
    position: relative;
    right: -40px;
  }

  .contact-hero {
    padding: 100px 0 67px 0;
  }

  .contact-hero {
    padding: 50px 0 67px 0;
  }

  .contact-hero__title {
    margin-bottom: 50px;
  }

  .contact-form input[type=submit] {
    margin-top: 38px;
  }

  .contact-form__textarea {
    height: 256px;
    margin-top: 38px;
  }

  .contact-form__row {
    margin-top: 38px;
  }

  .contact-images img {
    width: 100%;
    aspect-ratio: 16/9;
  }
}

@media only screen and (max-width: 575px) {
  .instructor-hero__img {
    max-width: 90%;
    right: -20px;
  }
}

@media only screen and (max-width: 400px) {
  .container {
    padding: 0 13px;
  }

  .logo {
    max-width: 200px;
    flex: 0 0 200px;
  }

  .partners__col {
    flex: 0 0 100%;
  }
}

@media (orientation: portrait) {
  .dimbox-container {
    padding: 56px 16px;
  }

  .dimbox-figure,
  .dimbox-video-container {
    max-width: 100%;
    height: 100%;
  }

  .dimbox-iframe-container,
  .dimbox-ajax-container {
    width: 100%;
    height: calc(100vh - 112px);
  }

  .dimbox-inline-content {
    max-height: calc(100vh - 112px);
  }

  .dimbox-image,
  .dimbox-video {
    width: 100%;
    height: auto;
  }

  .dimbox-btn-prev,
  .dimbox-btn-next {
    top: auto;
    bottom: 0;
  }
}